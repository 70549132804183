
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import FrListing from '../../assets/locales/fr/Listing.json';
import FrMenus from '../../assets/locales/fr/Menus.json';
import EnMenus from '../../assets/locales/en/Menus.json';
import EnListing from '../../assets/locales/en/Listing.json';
import EnImport from '../../assets/locales/en/Import.json';
import FrImport from '../../assets/locales/fr/Import.json';
import EnStatements from '../../assets/locales/en/Statements.json';
import FrStatements from '../../assets/locales/fr/Statements.json';
import EnImages from '../../assets/locales/en/Images.json';
import FrImages from '../../assets/locales/fr/Images.json';

export const defaultNS = 'main';
export const resources = {
  en: {
    Listing: EnListing,
    Menus: EnMenus,
    Import: EnImport,
    Statements: EnStatements,
    Images: EnImages
  },
  fr: {
    Listing: FrListing,
    Menus: FrMenus,
    Import: FrImport,
    Statements: FrStatements,
    Images: FrImages
  }
};

i18n
  .use(initReactI18next)
  .init({ lng: localStorage.getItem('lang') || 'en', defaultNS: [defaultNS], resources });

export default i18n;